<template>
  <v-data-table
    :headers="headers"
    :items="recordings"
    :sort-by="'creationTime.seconds'"
    :sort-desc="true"
    :item-class="defineRowClass"
  >
    <template v-slot:top>
      <v-dialog v-model="dialog" max-width="300px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{
              $t("msh.recordings.download.ready")
            }}</span>
          </v-card-title>
          <v-card-text>
            <a :href="downloadUrl">{{
              $t("msh.recordings.download.download")
            }}</a>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              {{ $t("msh.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="moreInfoDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t("msh.recordings.history") }}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :value="sessionId"
              label="Session id"
              readonly
            ></v-text-field>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Timestamp</th>
                    <th class="text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(status, i) in recordingHistory" :key="i">
                    <td>
                      {{
                        $d(new Date(status.timestamp.seconds) * 1000, "short")
                      }}
                    </td>
                    <td>{{ $t("msh.recordings.status." + status.status) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeMoreInfo">
              {{ $t("msh.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.creationTime.seconds="{ item }">
      <!-- Extract date following defined i18n short option -->
      <span v-if="item.creationTime">
        {{ $d(new Date(item.creationTime.seconds) * 1000, "short") }}
      </span>
    </template>
    <template v-slot:item.lastStatus="{ item }">
      <!-- Extract date following defined i18n short option -->
      <span v-if="item.lastStatus">
        {{ $t("msh.recordings.status." + item.lastStatus) }}
      </span>
    </template>
    <template v-slot:item.size="{ item }">
      <!-- Extract date following defined i18n short option -->
      <span v-if="item.size">
        {{ formatBytes(item.size) }}
      </span>
    </template>
    <template v-slot:item.download="{ item }">
      <!-- Extract date following defined i18n short option -->
      <v-btn v-if="item.path" text @click="openDownloadDialog(item)"
        >Download</v-btn
      >
    </template>
    <template v-slot:item.moreInfo="{ item }">
      <!-- Extract date following defined i18n short option -->
      <v-btn icon text @click="openMoreInfoDialog(item)"
        ><v-icon>mdi-information</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import {
  RECORDINGS_FETCH_REQUEST,
  RECORDINGS_LIST_FOR_STRUCTURE_REQUEST,
  RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE,
  RECORDING_GET_PROMISE,
} from "@/store/constants";

let swap = false;
let currentColor = "white";

export default {
  data() {
    return {
      dialog: false,
      moreInfoDialog: false,
      sessionId: "",
      recordingHistory: [],
      downloadUrl: "",
      headers: [
        { text: "Label", align: "start", sortable: true, value: "label" },
        { text: "Date", value: "creationTime.seconds", sortable: true },
        { text: "Last status", value: "lastStatus" },
        { text: "size", value: "size" },
        { text: "Serial number", value: "serialNumber" },
        // { text: "By user", value: "userEmail" },
        { text: "", value: "download" },
        { text: "", value: "moreInfo" },
      ],
    };
  },
  methods: {
    // Src: https://stackoverflow.com/a/18650828/218703
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    openDownloadDialog(item) {
      this.downloadUrl = "";
      store
        .dispatch(RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE, item.path)
        .then((url) => {
          // Trigger the download via the browser api
          this.downloadUrl = url;
          this.dialog = true;
        });
    },
    openMoreInfoDialog(item) {
      this.recordingHistory = [];
      this.sessionId = "";
      store
        .dispatch(RECORDING_GET_PROMISE, item.sessionId)
        .then((recordingResponse) => {
          recordingResponse.statusList.sort(
            (a, b) => a.timestamp.seconds <= b.timestamp.seconds
          );
          this.recordingHistory = recordingResponse.statusList;
          this.sessionId = recordingResponse.sessionId;
          this.moreInfoDialog = true;
        });
    },
    /**
     * Close the dialog window and erase the data
     */
    close() {
      this.dialog = false;
    },
    closeMoreInfo() {
      this.moreInfoDialog = false;
    },
    /**
     * define the color based on the group id and the previous group id
     * Need to know where we are on the list with item.
     * ...
     */
    defineRowClass(item) {
      let currentIndex = this.recordings.findIndex(
        (record) => record.id == item.id
      );
      if (currentIndex > 0) {
        let previousItem = this.recordings[currentIndex - 1];
        if (item.groupId != previousItem.groupId) {
          swap = true;
        } else {
          if (item.groupId == "" && previousItem.groupId == "") {
            swap = true;
          } else {
            swap = false;
          }
        }
      }

      if (swap) {
        if (currentColor == "white") {
          currentColor = "grey lighten-3";
        } else {
          currentColor = "white";
        }
      }
      return currentColor;
    },
  },
  mounted() {
    currentColor = "white";
  },
  /**
   * Section require to show and hide the dialog window
   */
  watch: {
    dialog(val) {
      val || this.close();
    },
    moreInfoDialog(val) {
      val || this.close();
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapState({
      recordings: (state) => state.recordings.structure_records,
    }),
    ...mapGetters(["isLoading"]),
  },
  async beforeRouteEnter(to, from, next) {
    // First checking that we have the list of recordings and navigating to the proper structure list.
    store.dispatch(RECORDINGS_FETCH_REQUEST, to.params.buildingId).then(() => {
      store.dispatch(RECORDINGS_LIST_FOR_STRUCTURE_REQUEST, {
        structure_id: to.params.structureId,
        floor_id: to.params.floorId,
      });
    });
    next();
  },
};
</script>
